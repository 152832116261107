<template>
  <div :is="billData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="billData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.bills.billNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-bill-list' }">
          {{ $t("message.bills.billList") }}
        </b-link>
        {{ $t("message.bills.forOtherBills") }}
      </div>
    </b-alert>

    <template v-if="billData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.bills.viewEdit") }}</h2>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="typeBill">
                <template v-slot:label>
                  {{ $t("message.tasks.type") }}
                </template>
                <v-select
                  v-model="billData.type"
                  :options="typeOptions"
                  :reduce="(val) => val.value"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="billData.type === 'isolate'">
              <b-form-group label-for="expiryDate">
                <template v-slot:label>
                  {{ $t("message.bills.expiryDate") }}
                </template>
                <b-form-datepicker
                  v-model="billData.expiryDate"
                  :locale="$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6" v-else>
              <b-form-group label-for="expiryDate">
                <template v-slot:label>
                  {{ $t("message.bills.expiryDate") }}
                </template>
                <b-form-input v-model="billData.expiryDay" disabled />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- text area -->
            <b-col md="12">
              <b-form-group label-for="titleBill">
                <template v-slot:label>
                  {{ $t("message.projects.description") }}
                </template>

                <b-alert variant="secondary" show>
                  <div class="alert-body">
                    <span v-html="billData.title"></span>
                  </div>
                </b-alert>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  v-if="restrictions('button_edit_bill')"
                  :to="{ name: 'apps-bills-edit', params: { id: billData.id } }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-if="restrictions('button_delete_bill')"
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-bill-list' }"
            @click="deleteBill()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BTab,
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
  },

  data() {
    return {
      userData: store.state.user.userData,
      typeOptions: [
        { label: "Isolado", value: "isolate" },
        { label: "Recorrente", value: "recurrent" },
      ],
    };
  },

  setup() {
    const billData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}bill/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        billData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          billData.value = undefined;
        }
      });

    return {
      billData,
    };
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    deleteBill() {
      axios({
        method: "delete",
        url: `${URL_API}bill/${this.billData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A conta foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            billData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.mtn {
  margin-top: -0.8rem;
}
</style>
